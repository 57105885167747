<template>
  <div class="feed">
    <el-form :label-position="'left'" label-width="140px" style="margin: 20px; width: 80%; ">
      <el-form-item label="教学名称">
        <el-input v-model="teach.teachName"></el-input>
      </el-form-item>
      <el-form-item label="多吉云vcode">
        <el-input v-model="teach.vcode"></el-input>
      </el-form-item>
      <el-form-item label="多吉云溯源水印">
        <el-input v-model="teach.dogeWaterMark"></el-input>
      </el-form-item>
      <el-form-item label="腾讯云fileId">
        <el-input v-model="teach.fileId"></el-input>
      </el-form-item>
      <!-- <el-form-item label="教学视频">
        <el-row>
          <video id="player" width="414" height="270" playsinline webkit-playsinline></video>
        </el-row>
        <el-row>
          <el-button type="primary" @click="dialogVisible = true" style="margin-top: 20px">选择</el-button>
        </el-row>
      </el-form-item> -->
      <el-form-item>
        <el-radio-group v-model="teach.teachType">
          <el-radio :label="3">feed对话模式</el-radio>
          <el-radio :label="5">recite背书模式</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="讲义原件">
        <el-input type="textarea" v-model="teach.lectureNote" :rows="10" placeholder="讲义名#cos_key"></el-input>
      </el-form-item>

      <el-form-item label="正文">
        <aidaFeedEditor
          :openMd="true"
          :openHtml="false"
          :openCos="true"
          :openVod="true"
          :openPractice="true"
          v-model="teach.content"
        ></aidaFeedEditor>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onAddTeach">{{ requestBtnText }}</el-button>
        <el-button>取消</el-button>
      </el-form-item>

      <el-dialog title="选择VOD" :visible.sync="dialogVisible" width="90%" height="90%">
        <vodSelector v-model="vodRet" v-on:get-select="onGetSelectVod"></vodSelector>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import aidaFeedEditor from "@/component/aidaFeedEditor";
import { alertMessage } from "@/util/alertMessageUtil";
import { MarkdownFileType, TeachTypeEnum } from "@/util/EnumUtil";
import { xteachApi } from "@/api/xteachApi.js";
import { vodApi } from "@/api/vodApi";
import vodSelector from "@/component/vodSelector.vue";
import msg from "@/util/msg.js";

export default {
  components: {
    aidaFeedEditor,
    vodSelector,
  },
  data() {
    return {
      player: null,
      dialogVisible: false,
      requestBtnText: "立即创建",
      requestStatus: false,
      vodRet: {},
      teach: {
        teachId: 0,
        teachName: "",
        teachType: TeachTypeEnum.FEED,
        content: [
          {
            unitType: MarkdownFileType.MARKDOWN,
            unitId: 0,
            index: 0,
            content: "",
            fileName: "",
            url: "",
            markDown: {
              content: "",
            },
          },
        ],
        fileId: "",
        vcode: "",
        lectureNote: "",
        dogeWaterMark: 0,
      },
    };
  },
  methods: {
    onAddTeach() {
      let alertText = "请填写名称";
      let alertType = "warning";
      this.requestStatus = true;
      if (!this.teach.teachName) {
        alertMessage(alertText, alertType);
        return;
      }
      console.log("----this.teach", this.teach);
      if (this.requestStatus) {
        xteachApi
          .addFeedTeach(this.teach)
          .then((ret) => {
            if (ret.code === 0) {
              if (this.requestBtnText === "立即创建") {
                alertMessage("创建成功", "success");
              } else {
                alertMessage("修改成功", "success");
              }
            } else {
              alertMessage(ret.data.msg, "warning");
            }
            this.teach = ret.data;
          })
          .catch((err) => {
            console.log("##", err);
            msg.error(err.data || err);
          });
      }
    },
    onGetSelectVod(val) {
      console.log("on select vod", val);
      this.teach.fileId = val.fileId;
      console.log(this.teach.fileId);
      vodApi
        .getVodParam(this.teach.fileId)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0) {
            this.teach.vodParam = data;
          }
        })
        .then(() => {
          let _this = this;
          console.log("vod param", _this.teach.vodParam);
          this.player.loadVideoByID({
            fileID: _this.teach.vodParam.fileID, // 请传入需要播放的视频filID 必须
            appID: _this.teach.vodParam.appID, // 请传入点播账号的appID 必须
            t: _this.teach.vodParam.t,
            us: _this.teach.vodParam.us,
            sign: _this.teach.vodParam.sign,
          });
        });

      this.dialogVisible = false;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.requestBtnText = "立即修改";
      xteachApi.getFeedTeachById(this.$route.params.id).then((ret) => {
        let { code, data } = ret;

        if (code === 0) {
          this.teach = data;
          console.log("teach", this.teach);
          if (!this.teach.content || !this.teach.content.length) {
            this.teach.content.push({
              unitType: MarkdownFileType.MARKDOWN,
              unitId: 0,
              index: 0,
              content: "",
              fileName: "",
              url: "",
              markDown: {
                content: "",
              },
            });
          }
          let _this = this;
          console.log(this.teach, "this.teach");
          // if (this.teach.vodParam) {
          //   let player = new this.$TcPlayer("player", {
          //     // player-container-id 为播放器容器ID，必须与html中一致
          //     fileID: _this.teach.vodParam.fileID, // 请传入需要播放的视频filID 必须
          //     appID: _this.teach.vodParam.appID, // 请传入点播账号的appID 必须
          //     t: _this.teach.vodParam.t,
          //     us: _this.teach.vodParam.us,
          //     sign: _this.teach.vodParam.sign,
          //   });
          //   this.player = player;
          // } else {
          //   let player = new this.$TcPlayer("player", {
          //     // player-container-id 为播放器容器ID，必须与html中一致
          //     fileID: "", // 请传入需要播放的视频filID 必须
          //     appID: _this.APP_ID, // 请传入点播账号的appID 必须
          //     autoplay: false,
          //   });
          //   this.player = player;
          // }
        } else {
          alertMessage("获取数据失败，请稍后再试", "warning");
        }
      });
    } else {
      // let _this = this;
      // let player = new this.$TcPlayer("player", {
      //   // player-container-id 为播放器容器ID，必须与html中一致
      //   fileID: "", // 请传入需要播放的视频filID 必须
      //   appID: _this.APP_ID, // 请传入点播账号的appID 必须
      //   autoplay: false,
      // });
      // this.player = player;
    }
  },
  destroyed() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style lang="less" scoped>
.feed {
  width: 70%;
}
</style>
